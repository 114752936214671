import axios from 'axios'

const url = process.env.VUE_APP_BLUENV === 'development' ? '/api' : '/api'
const headers = {
    'Authorization': 'Bearer 603c0d60-603d-11e8-bcc7-c9299595dc11',
    'Content-Type': 'application/json'
}

export default class ApiService {
    static async getMenu () {
        try {
            let { data } = await axios.get(`${url}/getUsers`, { headers })
            if (data.status === 'success') return Promise.resolve(data.data)
            Promise.resolve([])
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }

    static async uploadClientsFile (importFile) {
        try {
            let formData = new FormData()
            formData.append('importFile', importFile)

            let { data } = await axios.post(`${url}/uploadFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return Promise.resolve(data)
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }

    static async getImportData (fileName) {
        try {
            let { data } = await axios.get(`${url}/getImportData/${fileName}`, { headers })
            return Promise.resolve(data)
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }

    static async confirmImport (fileName) {
        try {
            let { data } = await axios.get(`${url}/confirmImport/${fileName}`, { headers })
            return Promise.resolve(data)
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }
}
