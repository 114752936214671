import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login'
import Users from '../views/Users'
import UserDetails from '../views/UserDetails'
import Notifications from '../views/Notifications'
import Logout from '../views/Logout'
import ChangePassword from '../views/ChangePassword'
import FactoringCompanies from '../views/FactoringCompanies'
import FactoringCompanyDetail from '../views/FactoringCompanyDetail'
import MasterImporter from '../views/MasterImporter'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/userDetails/:userId',
        name: 'userDetails',
        component: UserDetails
    },
    {
        path: '/settings/empresas-dragon',
        name: 'empresasDragon',
        component: FactoringCompanies
    },
    {
        path: '/settings/empresa-dragon-detail/:id?',
        name: 'empresasDragonDetails',
        component: FactoringCompanyDetail
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/users',
        name: 'users',
        component: Users
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        component: ChangePassword
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications
    },
    {
        path: '/masterImporter',
        name: 'MasterImporter',
        component: MasterImporter
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
