<template>
    <div>

    </div>
</template>

<script>
export default {
    name: 'Logout',
    data () {
        return {

        }
    },
    async beforeMount () {
        await this.$store.dispatch('logout')
        window.location.href = '/login'
    }
}
</script>

<style scoped>

</style>
