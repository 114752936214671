import moment from 'moment'
import Vue from 'vue'

Vue.filter('formatDate', function (value, fromFormat, toFormat = 'DD/MM/YYYY') {
    if (!value) return ''
    value = value.toString()
    return moment.utc(value, fromFormat).format(toFormat)
})

Vue.filter('currency', function (value, simbol = '$', decimals = 2) {
    if (!value) return 0
    value = parseFloat(value).toFixed(2)
    return `${simbol}${parseFloat(value).toLocaleString('en', { maximumFractionDigits: decimals })}`
})

Vue.filter('upper', function (value) {
    if (!value) return ''
    return value.toUpperCase()
})
