<template>
    <div id="app">
        <portal-target name="semantic-ui-vue" />
        <div v-if="$store.getters.isLoggedIn && !$store.getters.user.recoverPass">
            <TopNavigation />
            <div class="ui basic very padded segment">
                <sui-segment raised padded>
                    <router-view />
                </sui-segment>
            </div>
        </div>
        <div v-else>
            <router-view />
        </div>
    </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation'

export default {
    components: {
        TopNavigation
    }
}
</script>

<style>
body {
    background: #f2f2f2 !important;
}

#flexbox-wrapper {
    display: flex;
    width: 100vw;
    height: -webkit-fill-available;
    height: auto;
    min-height: 100vh;
}
#flexbox-wrapper > .sidebar {
    flex: 0 0 auto;
    width: 150px;
}
#flexbox-wrapper > .pusher {
    flex: 1 1 auto;
    background: #ededed;
}

#mainContent {
    width: 100vw;
}

@media (min-width: 800px) {
    #mobileMenu {
        display: none;
    }

    #mainContent {
        width: calc(100vw - 150px);
    }
}

.hidden {
    display: none;
}

table.selectable tr {
    cursor: pointer;
}

.msg {
    display: none;
}

html.ios {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
html.ios,
html.ios body {
    height: initial !important;
}
.gSignIn {
    float: right;
}
</style>
