<template>
    <sui-segment basic>
            <sui-button class="right floated" primary @click="saveUser">Guardar datos</sui-button>
        <sui-header dividing>Detalles de usuario</sui-header>
          <sui-form>
            <sui-form-field>
                <label>Nombre completo</label>
                <input placeholder="Ej. Ibrahim Abdul Yahar Badiger" v-model="userFullName" />
            </sui-form-field>
            <sui-form-field>
                <label>Correo electrónico</label>
                <input placeholder="Ej. correo@ejemplo.com" v-model="user.email"/>
            </sui-form-field>
            <sui-form-field>
                <label>Usuario</label>
                <input placeholder="Ej. Ibrahim Abdul Yahar Badiger" v-model="user.userName" />
            </sui-form-field>
            <sui-form-field>
                <sui-checkbox v-model="user.recoverPass" toggle label="Usuario debe reestablecer contraseña al iniciar sesion" />
            </sui-form-field>
            <sui-header dividing>Menus activos</sui-header>
            <sui-header dividing>Permisos</sui-header>
        </sui-form>

    </sui-segment>
</template>

<script>
import UserService from '@/services/UserService'
// import ApiService from '@/services/ApiService'

export default {
    name: 'userDetails',
    data () {
        return {
            user: {},
            menus: [],
            loader: undefined
        }
    },
    beforeMount () {
        if (!this.$store.getters.isLoggedIn) {
            window.location.replace('/login')
        }
        this.loader = this.$loading.show()
        this.getUserData(this.$route.params.userId)
        this.getMenus()
    },
    methods: {
        async getUserData (userId) {
            try {
                this.user = await UserService.getById(userId)
                this.loader.hide()
            } catch (error) {
                console.error(error)
                this.$swal({
                    title: error.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Volver al listado',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: false
                }).then(async (result) => {
                    let routeData = this.$router.resolve({ name: 'users' })
                    window.open(routeData.href, '_self')
                })
            }
        },
        getMenus () {
            try {
                this.menus = []
            } catch (error) {
                console.error(error)
            }
        },
        async saveUser () {
            this.loader = this.$loading.show()
            try {
                let updated = await UserService.saveUser(this.user)
                if (updated) {
                    this.$swal({
                        title: 'Se actualizaron los datos del usuario',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#2BBA45',
                        showCloseButton: true
                    })
                }
                this.loader.hide()
            } catch (error) {
                this.loader.hide()
                console.error(error)
                this.$swal({
                    title: error.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
            }
        }
    },
    computed: {
        userFullName () {
            return `${this.user.firstName || ''} ${this.user.fatherSurname || ''} ${this.user.motherSurname || ''}`.replace(/\s+/g, ' ').trim()
        }
    }

}
</script>

<style scoped>
</style>
