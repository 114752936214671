<template>
    <div class="changePassWrapper">
        <div class="ui container">
            <sui-segment very padded>
                <div class="ui warning message">
                    <div class="header">Actualiza tu contraseña</div>
                    <p>Para mantener tu cuenta asegurada es necesario que actualices la contraseña cumpliendo las siguientes reglas:</p>
                    <ul>
                        <li>8 caracteres como mínimo</li>
                        <li>1 letra en mayuscula</li>
                        <li>1 letra en minuscula</li>
                        <li>1 Número mínimo</li>
                        <li>1 caracter mínimo. (Por ejemplo: @, !, ?, &, *, #, ", etc)</li>
                    </ul>
                </div>

                <sui-button class="right floated" primary @click="savePass">Guardar</sui-button>
                <sui-header dividing>Actualizar contraseña</sui-header>
                <sui-form>
                    <sui-form-field>
                        <label>Usuario</label>
                        <input
                            disabled
                            placeholder="Ej. Ibrahim Abdul Yahar Badiger"
                            :value="$store.getters.user.userName"
                        />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Nueva contraseña</label>
                        <input type="password" v-model="newPassword" />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Confirmar contraseña nueva</label>
                        <input type="password" v-model="passwordConfirmation" />
                    </sui-form-field>
                </sui-form>
            </sui-segment>
        </div>
    </div>
</template>

<script>
import UserService from '@/services/UserService'

export default {
    name: 'changePassword',
    data () {
        return {
            loader: undefined,
            newPassword: undefined,
            passwordConfirmation: undefined
        }
    },
    methods: {
        isValidPassword () {
            if (!this.newPassword) {
                this.$swal({
                    title: 'Las contraseña no puede estar vacia',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
                return false
            }

            if (this.newPassword.length < 8) {
                this.$swal({
                    title: 'La contraseña debe contener al menos 8 caracteres',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
                return false
            }

            if (!`${this.newPassword}`.match(/[A-Z]/g)) {
                this.$swal({
                    title: 'La contraseña debe contener al menos 1 letra en mayuscula',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
                return false
            }

            if (!`${this.newPassword}`.match(/[a-z]/g)) {
                this.$swal({
                    title: 'La contraseña debe contener al menos 1 letra en minuscula',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
                return false
            }

            if (!`${this.newPassword}`.match(/[0-9]/g)) {
                this.$swal({
                    title: 'La contraseña debe contener al menos 1 caracter especial',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
                return false
            }

            if (!`${this.newPassword}`.match(/\W/g)) {
                this.$swal({
                    title: 'La contraseña debe contener al menos 1 caracter especial',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
                return false
            }

            if ((!this.newPassword || !this.passwordConfirmation) || this.newPassword !== this.passwordConfirmation) {
                this.$swal({
                    title: 'Las contraseñas no coinciden',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
                return false
            }

            return true
        },
        async savePass () {
            if (!this.isValidPassword()) return

            this.loader = this.$loading.show()
            try {
                let updated = await UserService.saveUser({
                    newPass: true,
                    pass: this.newPassword
                })
                if (updated) {
                    this.$swal({
                        title: 'Contraseña actualizada correctamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#2BBA45',
                        showCloseButton: true
                    }).then(async (result) => {
                        let routeData = this.$router.resolve({ name: 'home' })
                        window.open(routeData.href, '_self')
                    })
                }
                this.loader.hide()
            } catch (error) {
                this.loader.hide()
                console.error(error)
                this.$swal({
                    title: error.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: true
                })
            }
        }
    }
}
</script>

<style scoped>
.changePassWrapper {
    height: 100vh;
    align-content: center;
    display: grid;
    background: url(/pattern.png);
}
</style>
