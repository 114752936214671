<template>
    <section>
        <modal  v-if="showModal" @close="showModal = false"
                :title="'Revisión de facturas: ' + company.razonSocial"
                :sizeOfModal="'fullscreen'"
                :allowScrolling="true"
                :showElement="showModal">
            <template v-slot:modalContent>
                <div class="ui icon warning message">
                  <i class="info circle icon"></i>
                  <div class="content">
                    <div class="header">
                        Información importante
                    </div>
                    <p>Las facturas que se autoricen, serán compradas como Blú Index, de lo contrario serán rechazadas</p>
                  </div>
                </div>
                <div>
                    <sui-table compact>
                        <sui-table-header>
                          <sui-table-row>
                                <sui-table-header-cell style="width: 15%" class="txtc">¿Acción?</sui-table-header-cell>
                                <sui-table-header-cell style="width: 23%">Cliente</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtc">Folio</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtc">Fecha de Emisión</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtc">Compromiso de Pago</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtr">Total</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtc">Moneda</sui-table-header-cell>
                          </sui-table-row>
                        </sui-table-header>
                        <sui-table-body>
                            <sui-table-row v-for="invoice of record.invoices" :key="invoice.reviewId">
                                <sui-table-cell class="txtc">
                                    <sui-popup content="Autorizar compra de factura" size="mini" position="right center">
                                        <i class="check circle large icon" slot="trigger"
                                            :class="{
                                                'green big': invoice.newStatus === FactoringDocumentReviewStatus.APPROVED,
                                                'grey': invoice.newStatus !== FactoringDocumentReviewStatus.APPROVED
                                            }"
                                            @click="approve(invoice)"></i>
                                    </sui-popup>
                                    <sui-popup content="Rechazar compra de factura" size="mini" position="right center">
                                        <i class="times circle large icon"  slot="trigger"
                                            :class="{
                                                'red big': invoice.newStatus === FactoringDocumentReviewStatus.REJECTED,
                                                'grey': invoice.newStatus !== FactoringDocumentReviewStatus.REJECTED
                                            }"
                                            @click="reject(invoice)"></i>
                                    </sui-popup>
                                </sui-table-cell>
                                <sui-table-cell>{{invoice.rfc}} <br> <strong>{{invoice.entityName | upper }}</strong></sui-table-cell>
                                <sui-table-cell class="txtc">{{invoice.folio}}</sui-table-cell>
                                <sui-table-cell class="txtc">{{invoice.emittedOn | formatDate }}</sui-table-cell>
                                <sui-table-cell class="txtc">{{invoice.dueDate | formatDate }}</sui-table-cell>
                                <sui-table-cell class="txtr">{{invoice.total | currency}}</sui-table-cell>
                                <sui-table-cell class="txtc">{{invoice.currency}}</sui-table-cell>
                            </sui-table-row>
                        </sui-table-body>
                    </sui-table>
                   <br/>
                </div>
            </template>
            <template v-slot:modalFooter>
                <div class="actions">
                    <sui-button positive @click="close()">Terminar revisión</sui-button>
                    <sui-button @click="close(true)">Cancelar</sui-button>
                </div>
            </template>
        </modal>
    </section>
</template>

<script>

import Modal from '@/components/Helpers/Modal.vue'
import FactoringDocumentReviewStatus from '@/common/FactoringDocumentReviewStatus.js'
import Vue from 'vue'
import moment from 'moment'

export default {
    name: 'ReviewFactoringDocument',
    components: { Modal },
    props: ['showModal', 'company', 'invoices'],
    data () {
        return {
            FactoringDocumentReviewStatus: FactoringDocumentReviewStatus,
            record: {
                cancel: false,
                invoices: [...this.invoices || []]
            }
        }
    },
    mounted () {
        if (this.invoices) {
            this.record.invoices = [ ...this.invoices ]
        }
    },
    methods: {
        approve (invoice) {
            if (invoice.newStatus === FactoringDocumentReviewStatus.APPROVED) {
                Vue.set(invoice, 'newStatus', FactoringDocumentReviewStatus.PENDING)
                Vue.set(invoice, 'reviewOn', null)
                Vue.set(invoice, 'userId', null)
                return
            }
            let now = new Date()
            invoice.newStatus = FactoringDocumentReviewStatus.APPROVED
            Vue.set(invoice, 'newStatus', FactoringDocumentReviewStatus.APPROVED)
            Vue.set(invoice, 'reviewOn', moment(now.toISOString()))
            Vue.set(invoice, 'userId', this.$store.getters.user.userId)
        },
        reject (invoice) {
            if (invoice.newStatus === FactoringDocumentReviewStatus.REJECTED) {
                Vue.set(invoice, 'newStatus', FactoringDocumentReviewStatus.PENDING)
                Vue.set(invoice, 'reviewOn', null)
                Vue.set(invoice, 'userId', null)
                return
            }
            let now = new Date()
            Vue.set(invoice, 'newStatus', FactoringDocumentReviewStatus.REJECTED)
            Vue.set(invoice, 'reviewOn', moment(now.toISOString()))
            Vue.set(invoice, 'userId', this.$store.getters.user.userId)
        },
        close (cancel = false) {
            if (cancel) {
                this.record.cancel = true
                this.$emit('modalResponse', this.record)
                return
            }
            this.record.reviewedInvoices = this.record.invoices.filter(item => item.newStatus !== FactoringDocumentReviewStatus.PENDING)
            this.$swal({
                title: '¿Deseas confirmar la operación?',
                html: 'Las facturas se procesarán de acuerdo a lo indicado',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si, terminar',
                confirmButtonColor: '#2BBA45',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                showCloseButton: true
            }).then(async (result) => {
                if (result.value === true) {
                    this.record.cancel = false
                    this.$emit('modalResponse', this.record)
                }
            })
        }
    }
}
</script>
<style scoped>
    .txtc { text-align: center!important }
    .txtr { text-align: right!important }
</style>
