import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SuiVue from 'semantic-ui-vue'
import axios from 'axios'
import configurations from './common/configurations'
import PortalVue from 'portal-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import './common/Filters'
import Notifications from './plugins/Notifications'

const url =
process.env.VUE_APP_BLUENV === 'production' || process.env.VUE_APP_BLUENV === 'test'
    ? '/api'
    : '/api'

Vue.config.productionTip = false
Vue.use(SuiVue)
Vue.use(PortalVue)
Vue.use(VueSweetalert2)
Vue.use(Loading, configurations.optionsLoader)
Vue.use(Notifications)

Vue.config.errorHandler = async (err, vm, info) => {
    console.error(err)
    if (process.env.VUE_APP_BLUENV !== 'development') {
        const errorId = Math.random().toString(36).substr(4).toUpperCase()
        await axios.post(`/api/logError`, {
            errorId,
            error: err.stack
        })

        Vue.swal({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timerProgressBar: true,
            showCloseButton: true,
            timer: 10000,
            icon: 'error',
            title: 'Ooppss',
            html: `Hubo un error, favor de reportar a BIITES con número de error <br/><strong>${errorId}</strong>`
        })
    }
}

axios.defaults.withCredentials = true

axios
    .get(`${url}/getSession${process.env.VUE_APP_BLUENV === 'development' ? '/true' : ''}`)
    .then(response => {
        let { data } = response
        new Vue({
            async beforeCreate () {
                this.$store.dispatch('setUser', {
                    isLoggedIn: data.data.isLoggedIn || 0,
                    user: data.data.user || {}
                })
            },
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    })
    .catch(error => { throw new Error(error) })
