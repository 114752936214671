import FactoringCompanyStatus from '@/common/FactoringCompanyStatus'
import FactoringDocumentReviewStatus from '@/common/FactoringDocumentReviewStatus'
import store from '@/store'

export default class Utils {
    static userHasPerm (permission) {
        if (store.getters.user.roleId === 1) { return true }
        let permissions = store.getters.user.permissions[process.env.VUE_APP_BLUAPP] || []
        let index = permissions.findIndex(perm => perm === permission)
        if (index >= 0) { return true }
        return false
    }

    static isObjectEmpty (obj) {
        if (obj === undefined || !obj) {
            return true
        }
        return Object.entries(obj).length === 0 && obj.constructor === Object
    }

    static getStatusInfo (statusId) {
        if (statusId === FactoringCompanyStatus.REJECTED) { return { name: 'RECHAZADA', color: 'red', statusId: statusId } }
        if (statusId === FactoringCompanyStatus.REJECTED_FOR_SHELL_COMPANY) { return { name: 'RECHAZADA POR EMPRESA FANTASMA', color: 'red', statusId: statusId } }
        if (statusId === FactoringCompanyStatus.REJECTED_FOR_PLD) { return { name: 'RECHAZADA POR PLD', color: 'red', statusId: statusId } }
        if (statusId === FactoringCompanyStatus.PENDING) { return { name: 'PENDIENTE', color: 'black', statusId: statusId } }
        if (statusId === FactoringCompanyStatus.APPROVED) { return { name: 'APROBADA', color: 'green', statusId: statusId } }
        if (statusId === FactoringCompanyStatus.DISABLED) { return { name: 'DESHABILITADA', color: 'grey', statusId: statusId } }
        return statusId
    }

    static getReviewDocumentStatusInfo (statusId) {
        if (statusId === FactoringDocumentReviewStatus.PENDING) { return { name: 'PENDIENTE', color: 'black', statusId: statusId } }
        if (statusId === FactoringDocumentReviewStatus.APPROVED) { return { name: 'APROBADA', color: 'green', statusId: statusId } }
        if (statusId === FactoringDocumentReviewStatus.REJECTED) { return { name: 'RECHAZADA', color: 'red', statusId: statusId } }
        return statusId
    }
}
