<template>
    <section>
        <modal  v-if="showModal" @close="showModal = false"
                v-on:keydown.esc="showModal = false"
                title="Rechazar empresa dragón"
                :sizeOfModal="'small'"
                :showElement="showModal">
            <template v-slot:modalContent>
                <form class="ui form">
                    <div class="field">
                        <label>¿Cúal es el motivo de rechazo?</label>
                        <select class="ui fluid dropdown"
                                v-model="record.statusId">
                            <option v-for="option in rejectionOptions" :value="option.value" :key="option.value">{{option.text}}</option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Descripción (opcional)</label>
                        <textarea rows="2" v-model="record.motive"></textarea>
                    </div>
                </form>
            </template>
            <template v-slot:modalFooter>
                <div class="actions">
                    <sui-button negative @click="reject()">Rechazar</sui-button>
                    <sui-button @click="reject(true)">Cancelar</sui-button>
                </div>
            </template>
        </modal>
    </section>
</template>

<script>

import Modal from '@/components/Helpers/Modal.vue'
import Utils from '@/common/Utils.js'
import FactoringCompanyStatus from '@/common/FactoringCompanyStatus.js'

export default {
    name: 'RejectModal',
    components: { Modal },
    props: ['showModal', 'company'],
    data () {
        return {
            Utils: Utils,
            record: { cancel: false, statusId: FactoringCompanyStatus.REJECTED },
            rejectionOptions: [
                {
                    value: FactoringCompanyStatus.REJECTED,
                    text: 'RECHAZO GENERAL'
                },
                {
                    value: FactoringCompanyStatus.REJECTED_FOR_PLD,
                    text: Utils.getStatusInfo(FactoringCompanyStatus.REJECTED_FOR_PLD).name
                },
                {
                    value: FactoringCompanyStatus.REJECTED_FOR_SHELL_COMPANY,
                    text: Utils.getStatusInfo(FactoringCompanyStatus.REJECTED_FOR_SHELL_COMPANY).name
                }
            ]
        }
    },
    methods: {
        reject (cancel = false) {
            if (cancel) {
                this.record.cancel = true
                this.$emit('modalResponse', this.record)
                return
            }
            this.$swal({
                title: '¿Deseas rechazar la empresa?',
                html: 'Las facturas de este rfc serán rechazadas',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si, rechazar',
                confirmButtonColor: '#2BBA45',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                showCloseButton: true
            }).then(async (result) => {
                this.record.cancel = false
                this.$emit('modalResponse', this.record)
            })
        },
        close () {
            this.$emit('modalResponse')
        }
    }
}
</script>
