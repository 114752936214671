<template>
    <section>
        <button
            v-if="unreadNotifications>0"
            class="ui right floated mini basic button"
            @click="markAllAsRead"
        >Marcar todo como leido</button>
        <h3 class="ui header">Notificaciones</h3>

        <div class="ui relaxed divided list">
            <div
                class="item"
                v-for="(notification, index) in notifications"
                :key="notification.notificationId"
            >
                <div class="content">
                    <div class="ui right floated description">{{ notification.createdAt }}</div>
                    <i
                        @click="markAsSeen(index)"
                        class="blue dot circle link icon left floated"
                        v-show="!notification.seen"
                    ></i>
                    <a
                        class="header"
                        :href="`${ notification.uri || '#' }`"
                        target="_blank"
                    >{{ notification.description }}</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'Notifications',
    beforeMount () {
        if (!this.$store.getters.isLoggedIn) {
            window.location.replace('/login')
        }
        this.getNotifications()
    },
    data () {
        return {
            loader: undefined,
            notifications: []
        }
    },
    methods: {
        async getNotifications () {
            this.loader = this.$loading.show()
            try {
                let response = await this.$notifications.getNotifications(this.$store.getters.user.userId)
                if (response.status === 'success') this.notifications = response.data.notifications
                this.loader.hide()
            } catch (error) {
                console.error(error)
                this.loader.hide()
            }
        },
        async markAllAsRead () {
            try {
                let response = await this.$notifications.readAll(this.$store.getters.user.userId)
                if (response.status === 'success') {
                    for (let notification of this.notifications) {
                        notification.seen = true
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },
        async markAsSeen (index) {
            try {
                let response = await this.$notifications.readOne(this.$store.getters.user.userId, this.notifications[index].notificationId)
                console.info('response ', response)
                if (response.status === 'success') this.notifications[index].seen = true
            } catch (error) {
                console.error(error)
            }
        }
    },
    computed: {
        unreadNotifications () {
            return this.notifications.reduce((accumulator, item) => {
                return accumulator + (item.seen ? 0 : 1)
            }, 0)
        }
    }
}
</script>

<style>
</style>
