<template>
  <div class="ui top fixed inverted stackable menu">
    <a href="/" class="item">
      <img src="@/assets/logo_w.png">
    </a>

    <div class="menuWrapper" v-for="menu in $store.getters.user.menu" :key="menu.menuId">
      <div v-if="menu.childs.length > 0" class="ui simple dropdown item">
        {{menu.menuName}}
        <i class="dropdown icon"></i>
        <div class="menu">
          <a v-for="subMenu in menu.childs" :key="subMenu.menuId" :href="`${subMenu.uri}`" :target="subMenu.target" class="item">{{ subMenu.menuName }}</a>
        </div>
      </div>
      <a v-else :href="`${menu.uri}`" class="item" :target="menu.target">{{ menu.menuName }}</a>
    </div>

    <div class="right menu">
      <Notifications/>
      <!-- <div class="ui simple dropdown item">
        <i class="cog icon"></i>
        <i class="dropdown icon"></i>
        <div class="menu">
          <a href="/settings/empresas-dragon/" class="item">Empresas Dragón</a>
          <a href="/settings/users/" class="item">Usuarios</a>
        </div>
      </div> -->
      <div class="ui simple dropdown item">
        {{ $store.getters.user.userName }}
        <i class="dropdown icon"></i>
        <div class="left menu">
          <a href="/Profile" class="item">Cambiar contraseña</a>
          <a href="/logout" class="item">Cerrar Sesión</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from '@/components/Notifications'

export default {
    name: 'TopNavigation',
    components: {
        Notifications
    },
    data () {
        return {
            baseUrl: process.env.VUE_APP_BASEPATH || ''
        }
    }
}
</script>

<style scoped>
.menuWrapper{
  display: flex;
  align-items: center;
}
</style>
