<template>
    <sui-modal id="modal" :size="modalSize" :closable='false' aligned="top" v-model="showModal" >
      <sui-modal-header>
        {{title}}
      </sui-modal-header>
      <sui-modal-content :scrolling="scrolling">
        <slot name="modalContent"></slot>
      </sui-modal-content>
      <sui-modal-actions>
        <slot name="modalFooter"></slot>
      </sui-modal-actions>
    </sui-modal>
</template>

<script>
import Vue from 'vue'
export default {
    props: ['title', 'showElement', 'sizeOfModal', 'allowScrolling'],
    name: 'Modal',
    async mounted () {
        if (this.showElement) {
            Vue.set(this, 'showModal', this.showElement)
        }
        if (this.sizeOfModal) {
            Vue.set(this, 'modalSize', this.sizeOfModal)
        }
        if (this.allowScrolling) {
            Vue.set(this, 'scrolling', this.allowScrolling)
        }
        document.getElementById('app').appendChild(this.$el)
    },
    data () {
        return {
            showModal: false,
            modalSize: 'standard',
            scrolling: false
        }
    }
}
</script>

<style lang="css" scoped>
    .content {
      padding-top: 10px!important;
    }
</style>
