<template>
    <section>
        <div class="ui basic segment">
            <a @click="$router.go(-1)" class="ui left basic button">
                <i class="reply icon"></i>Regresar
            </a>
            <h2 class="ui grey right floated header">
                <i class="edit icon"></i>
                Detalle de empresa dragón
            </h2>
        </div>

        <div class="ui grid">
            <div class="thirteen wide column">
                <sui-segment>
                    <div class="ui icon negative message" v-if="errors.length > 0">
                      <i class="exclamation triangle icon"></i>
                      <div class="content">
                        <div class="header">
                          Verifica los siguientes datos:
                        </div>
                        <ul>
                            <li v-for="error of errors" :key="error">{{error}}</li>
                        </ul>
                      </div>
                    </div>
                    <form class="ui form">
                        <h4 class="ui dividing blue header">Datos generales</h4>
                        <div class="field">
                            <div class="fields">
                                <div class="five wide required field" :class="{'error': !record.rfc }">
                                    <label>R.F.C.</label>
                                    <input type="text"
                                           placeholder="Escribe el R.F.C. de la empresa"
                                           v-model="record.rfc"
                                           v-if="!record.extranjera && !record.companyId">
                                    <strong class="ui blue header" v-else>{{ record.rfc }}</strong>
                                </div>
                                <div class="two wide field">
                                </div>
                                <div class="three wide field">
                                    <label>¿Es compañia extranjera?</label>
                                    <div class="ui toggle checkbox">
                                        <input type="checkbox" v-model="record.extranjera" @change="checkForeingCompany()" :disabled="record.companyId">
                                        <label></label>
                                    </div>
                                </div>
                                <div class="two wide field">
                                </div>
                                <div class="four wide field">
                                    <label>Estatus</label>
                                    <strong class="ui header" :class="Utils.getStatusInfo(record.statusId).color">
                                        {{ Utils.getStatusInfo(record.statusId).name }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="required field" :class="{'error': !record.razonSocial }">
                            <label>Razón Social</label>
                            <input type="text" placeholder="Escribe la razón social de la empresa" v-model="record.razonSocial" v-if="record.editing">
                            <span v-else> {{ record.razonSocial }} </span>
                        </div>
                        <div class="field">
                            <label>Nombre Comercial (Opcional)</label>
                            <input type="text" placeholder="Escribe el nombre comercial la empresa" v-model="record.nombreComercial" v-if="record.editing">
                            <span v-else> {{ record.nombreComercial }} </span>
                        </div>

                        <div v-if="Utils.userHasPerm('can_approve_factoring_company')">
                            <h4 class="ui dividing blue header">¿A cuáles productos tiene acceso?</h4>
                            <div class="field">
                                <div class="ui toggle checkbox">
                                    <input type="checkbox" v-model="record.factorajeBlu" :disabled="!record.editing">
                                    <label>Factoraje Blú (American Industries)</label>
                                </div>
                            </div>
                            <div class="field">
                                <div class="ui toggle checkbox">
                                    <input type="checkbox" v-model="record.bluIndex" :disabled="!record.editing">
                                    <label>Blú Index</label>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <h4 class="ui dividing blue header">¿A cuáles productos tiene acceso?</h4>
                            <div class="field">
                                <i class="check circle accepted large icon" v-if="record.factorajeBlu"></i>
                                <i class="times circle rejected large icon" v-if="!record.factorajeBlu"></i>
                                Factoraje Blú (American Industries)
                            </div>
                            <div class="field">
                                <i class="check circle accepted large icon" v-if="record.bluIndex"></i>
                                <i class="times circle rejected large icon" v-if="!record.bluIndex"></i>
                                Blú Index
                            </div>
                        </div>
                    </form>
                    <div v-show="record.companyId && !record.editing && canAddComments" style="padding-top: 50px;">
                        <h4 class="ui dividing header blue">
                          <i class="comment outline icon"></i>
                          <div class="content">Añadir comentario</div>
                        </h4>
                        <sui-form v-show="record.companyId && !record.editing">
                            <sui-form-field :class="{'error': commentsErrors}">
                              <label></label>
                                <textarea v-model="newComment" id="comment" type="text" rows="2" placeholder="Escriba un comentario" class="field-value form-control"></textarea>
                                <div class="ui pointing red basic label" v-if="commentsErrors">
                                  {{commentsErrors}}
                                </div>
                            </sui-form-field>
                        </sui-form>
                        <sui-button size="mini"
                            v-show="record.companyId && !record.editing"
                            content="Agregar"
                            color="green"
                            @click="addComment()"
                            style="margin-top: 10px;"
                            :class="{'disabled': !newComment}"/>

                    </div>
                    <div v-show="record.companyId" style="padding-top: 30px;">
                        <sui-comment-group>
                            <h3 is="sui-header" dividing>Comentarios ({{record.comments.length}})</h3>
                            <sui-comment v-for="(comment, indexComment) of record.comments" :key="indexComment">
                              <sui-comment-content>
                                <span is="sui-comment-author" class="ui header blue">{{comment.added_by}}</span>
                                <sui-comment-metadata>
                                  <div>{{comment.added_on}}</div>
                                </sui-comment-metadata>
                                <sui-comment-text>{{comment.comment}}</sui-comment-text>
                              </sui-comment-content>
                            </sui-comment>
                        </sui-comment-group>
                    </div>
                </sui-segment>
            </div>
            <div class="three wide column">
                <sui-grid>
                    <sui-grid-row>
                        <sui-grid-column textAlign="center">
                            <div class="ui vertical fluid menu">
                                <div class="header header-menu">ACCIONES</div>
                                <div class="link item" @click="editRecord()" v-if="!record.editing && record.companyId">
                                    Editar
                                </div>
                                <div class="link item" @click="saveRecord()" v-if="!record.companyId && record.editing">
                                    Guardar nueva empresa
                                </div>
                                <div class="link item" @click="saveRecord()" v-if="record.companyId && record.editing">
                                    Guardar
                                </div>
                                <div class="link item" @click="cancelEditRecord()" v-if="record.editing && record.companyId">
                                    Cancelar
                                </div>
                                <div class="link item"
                                    v-if="record.statusId === FactoringCompanyStatus.PENDING && record.companyId && record.editing === false"
                                    @click="rejectRecordInit()">
                                    Rechazar
                                </div>
                                <div class="link item"
                                    v-if="record.statusId === FactoringCompanyStatus.PENDING && record.companyId && record.editing === false"
                                    @click="approveRecord()">
                                    Aprobar
                                </div>
                                <div class="link item"
                                    v-if="record.statusId === FactoringCompanyStatus.APPROVED && record.companyId && record.editing === false"
                                    @click="disableRecord()">
                                    Deshabilitar
                                </div>
                                <div class="link item"
                                    v-if="record.statusId === FactoringCompanyStatus.DISABLED && record.companyId && record.editing === false"
                                    @click="enableRecord()">
                                    Habilitar
                                </div>
                                <div class="link item"
                                    v-if="isRejected && record.editing === false"
                                    @click="approveRejectedRecord()">
                                    Aprobar
                                </div>
                                <div class="link item"
                                    v-if="(record.statusId === FactoringCompanyStatus.DISABLED || record.statusId === FactoringCompanyStatus.REJECTED) && record.companyId && record.editing === false && record.invoicesToReview.length > 0"
                                    @click="reviewInvoices()">
                                    Autorizar facturas
                                </div>
                                <div class="link item"
                                    v-if="record.companyId && record.editing === false && record.invoices.length > 0"
                                    @click="openReviewedInvoices()">
                                    Historial de facturas autorizadas
                                </div>
                            </div>
                        </sui-grid-column>
                    </sui-grid-row>
                </sui-grid>
            </div>
        </div>
        <Reject :showModal="showRejectModal" :company="record" @modalResponse="rejectRecordFinish"/>
        <ReviewFactoringDocument :key="reviewModalKey" :showModal="showReviewModal" :company="record" :invoices="record.invoicesToReview" @modalResponse="saveReviews"/>
        <ReviewedInvoices :key="reviewedInvoicesKey" :showModal="showHistoryReviewModal" :company="record" @modalResponse="closeReviewesInvoices()"/>
    </section>
</template>

<script>

import FactoringCompanyService from '@/services/FactoringCompanyService'
// import IOCService from '@/services/IOCService'
import UserNotificationService from '@/services/UserNotificationService'
import FactoringDocumentReviewService from '@/services/FactoringDocumentReviewService'
import FactoringCompanyStatus from '@/common/FactoringCompanyStatus.js'
import FactoringDocumentReviewStatus from '@/common/FactoringDocumentReviewStatus.js'
import Reject from '@/components/Modals/Reject.vue'
import ReviewFactoringDocument from '@/components/Modals/ReviewFactoringDocument.vue'
import ReviewedInvoices from '@/components/Modals/ReviewedInvoices.vue'
import Utils from '@/common/Utils.js'
import Vue from 'vue'
import moment from 'moment'

export default {
    props: [],
    components: { Reject, ReviewFactoringDocument, ReviewedInvoices },
    data () {
        return {
            pruebas: process.env.VUE_APP_BLUENV !== 'production',
            showRejectModal: false,
            showReviewModal: false,
            showHistoryReviewModal: false,
            reviewModalKey: 0,
            reviewedInvoicesKey: 0,
            FactoringCompanyStatus: FactoringCompanyStatus,
            Utils: Utils,
            statusOptions: [],
            errors: [],
            commentsErrors: '',
            newComment: '',
            record: {
                rfc: '',
                extranjera: false,
                statusId: FactoringCompanyStatus.PENDING,
                razonSocial: '',
                nombreComercial: '',
                factorajeBlu: false,
                bluIndex: false,
                comments: [],
                editing: true
            }
        }
    },
    async beforeMount () {
        if (!this.$store.getters.isLoggedIn) {
            window.location.replace('/logout')
        }
        if (!this.$route.params.id && !Utils.userHasPerm('can_add_factoring_company')) {
            this.$swal({
                title: 'No tienes permiso para realizar la acción',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Volver al listado',
                confirmButtonColor: '#2BBA45',
                showCloseButton: false
            }).then(async (result) => {
                let routeData = this.$router.resolve({ name: 'empresasDragon' })
                window.open(routeData.href, '_self')
            })
        }
    },
    mounted () {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                if (this.showRejectModal) {
                    Vue.set(this, 'showRejectModal', false)
                }
            }
        })
        if (this.$route.params.id) {
            this.loadRecord(this.$route.params.id)
        }
    },
    filters: {
        upper (value) {
            return value.toUpperCase()
        }
    },
    computed: {
        isRejected () {
            if (this.record.statusId === FactoringCompanyStatus.REJECTED ||
                this.record.statusId === FactoringCompanyStatus.REJECTED_FOR_SHELL_COMPANY ||
                this.record.statusId === FactoringCompanyStatus.REJECTED_FOR_PLD) {
                return true
            }
            return false
        },
        canAddComments () {
            return Utils.userHasPerm('can_add_comment_factoring_company')
        }
    },
    methods: {
        editRecord () {
            if (!Utils.userHasPerm('can_edit_factoring_company')) {
                this.$swal({
                    title: 'Error',
                    text: 'No tienes permiso para realizar la accion',
                    type: 'error'
                })
                return
            }
            Vue.set(this.record, 'editing', true)
        },
        cancelEditRecord () {
            Vue.set(this.record, 'editing', false)
            this.loadRecord(this.$route.params.id)
        },
        async loadRecord (id) {
            let loader = this.$loading.show()
            try {
                let { data } = await FactoringCompanyService.getOne(id, { withInvoices: true })
                Vue.set(this, 'record', data)
                Vue.set(this.record, 'editing', false)
                this.record.invoicesToReview = this.record.invoices.filter(item => item.status === FactoringDocumentReviewStatus.PENDING)
                this.record.invoicesToReview = this.record.invoicesToReview.map(item => {
                    let invoice = Object.assign({}, item)
                    invoice.newStatus = item.status
                    return invoice
                })
                // await this.loadCreditDetail()
                loader.hide()
            } catch (error) {
                console.error(error)
                loader.hide()
                this.$swal({
                    title: 'La empresa no existe',
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Volver al listado',
                    confirmButtonColor: '#2BBA45',
                    showCloseButton: false
                }).then(async (result) => {
                    this.$router.push({ path: '/' })
                })
            }
        },
        async loadRecordByRFC () {
            try {
                let { data } = await FactoringCompanyService.getByRFC({ rfc: this.record.rfc })
                return data
            } catch (error) {
                console.error(error)
                return [{}]
            }
        },
        validateData () {
            this.errors = []
            this.record.rfc = this.record.rfc.toUpperCase().trim()
            this.record.razonSocial = this.record.razonSocial.toUpperCase().trim()
            this.record.nombreComercial = this.record.nombreComercial.toUpperCase().trim()
            this.record.rfc = this.record.rfc.replace(/\s/g, '')
            if (!this.record.rfc) {
                this.errors.push('Indica el RFC')
            }
            if (this.record.rfc === 'XEXX010101000') {
                this.record.extranjera = true
                // this.errors.push('El RFC indicado sólo es valido para empresas extranjeras, marca la casilla')
            }
            if (this.record.rfc.length > 0 && (this.record.rfc.length !== 12 && this.record.rfc.length !== 13)) {
                this.errors.push('El RFC tiene longitud incorrecta (12 o 13 caracteres, y no debe contener espacios)')
            }
            if (!this.record.razonSocial) {
                this.errors.push('Indica la razon social')
            }
            // if (Utils.userHasPerm('can_approve_factoring_company')) {
            if (this.record.statusId === FactoringCompanyStatus.APPROVED) {
                if (!this.record.factorajeBlu && !this.record.bluIndex) {
                    this.errors.push('La empresa está aprobada, debe tener al menos un producto asignado')
                }
            }
        },
        async saveRecord () {
            let loader = this.$loading.show()
            await this.validateData()
            if (this.errors.length > 0) {
                loader.hide()
                return
            }
            if (this.record.companyId) {
                // if (!this.record.extranjera) {
                //     let duplicated = await this.loadRecordByRFC(this.record.rfc)
                //     if (duplicated.length > 0) {
                //         loader.hide()
                //         if (duplicated[0].companyId !== this.record.companyId) {
                //             this.$swal('Error', 'El RFC indicado ya se encuentra registrado y no se pueden duplicar', 'error')
                //             return
                //         }
                //     }
                // }
                try {
                    await FactoringCompanyService.update(this.record)
                    // console.info(data)
                    await this.loadRecord(this.record.companyId)
                    loader.hide()
                } catch (error) {
                    console.error(error)
                    loader.hide()
                    this.$swal('Error', 'Error al guardar empresa', 'error')
                }
            } else {
                if (!this.record.extranjera) {
                    let duplicated = await this.loadRecordByRFC(this.record.rfc)
                    if (duplicated.length > 0) {
                        loader.hide()
                        this.$swal('Error', 'El RFC indicado ya se encuentra registrado', 'error')
                        return
                    }
                }

                let now = new Date()
                this.record.history = [{
                    date: moment(now.toISOString()).locale('es').format('DD-MMM-YYYY hh:mm A'),
                    type: 'ADDED',
                    info: 'Empresa dada de alta',
                    user: this.$store.getters.user.userName
                }]

                try {
                    let { data } = await FactoringCompanyService.create(this.record)
                    // TODO: Si el usuario puede aprobar, que se mande a aprobar
                    loader.hide()
                    Vue.set(this.record, 'companyId', data.companyId)
                    Vue.set(this.record, 'editing', false)
                    this.$swal('Operacion exitosa', 'Se ha creado la empresa', 'success')
                    // let routeData = this.$router.resolve({ name: 'empresasDragonDetails', params: { id: this.record.companyId } })
                    // window.open(routeData.href, '_self')
                } catch (error) {
                    let msg = error.data.message || 'Error al crear empresa, contacta al administrador'
                    loader.hide()
                    this.$swal('Error', msg, 'error')
                }
            }
        },
        checkForeingCompany () {
            if (this.record.extranjera) {
                this.record.rfc = 'XEXX010101000'
            }
        },
        rejectRecordInit () {
            if (!Utils.userHasPerm('can_reject_factoring_company')) {
                this.$swal('Error', 'No tienes permiso para realizar la accion', 'error')
                return
            }
            this.showRejectModal = true
        },
        async rejectRecordFinish (record) {
            if (record.cancel) {
                this.showRejectModal = false
            } else {
                this.showRejectModal = false
                let loader = this.$loading.show()

                let motive = 'Empresa Rechazada'
                if (record.motive && record.motive.length > 3) {
                    motive = record.motive
                }
                this.record.statusId = record.statusId

                let type = 'REJECTED'
                if (record.statusId === FactoringCompanyStatus.REJECTED_FOR_PLD) { type = 'REJECTED_FOR_PLD' }
                if (record.statusId === FactoringCompanyStatus.REJECTED_FOR_SHELL_COMPANY) { type = 'REJECTED_FOR_SHELL_COMPANY' }

                let now = new Date()
                this.record.history.unshift({
                    date: moment(now.toISOString()).locale('es').format('DD-MMM-YYYY hh:mm A'),
                    type: type,
                    info: motive,
                    user: this.$store.getters.user.userName
                })
                try {
                    let { data } = await FactoringCompanyService.update(this.record)
                    console.info(data)
                    loader.hide()
                    this.$swal('Operacion exitosa', 'Se ha rechazado la empresa', 'success')
                    await this.loadRecord(this.record.companyId)
                    try {
                        let request = { company: this.record, action: 'Empresa dragón Rechazada' }
                        UserNotificationService.notifyOnCompanyUpdate(request)
                    } catch (error) {
                        console.error('error on notifyOnCompanyUpdate', error)
                    }
                    // try {
                    //     IOCService.nofifyIOC(this.record)
                    // } catch (error) {
                    //     console.error('error on notify IOC', error)
                    // }
                } catch (error) {
                    console.error(error)
                    loader.hide()
                    this.$swal('Error', 'Error al guardar empresa', 'error')
                }
            }
        },
        approveRecord () {
            if (!Utils.userHasPerm('can_approve_factoring_company')) {
                this.$swal('Error', 'No tienes permiso para realizar la accion', 'error')
                return
            }
            if (!this.record.factorajeBlu && !this.record.bluIndex) {
                this.$swal('Error', 'Debes editar y asignar el producto a autorizar', 'error')
                return
            }
            this.$swal({
                title: '¿Deseas aprobar la empresa?',
                html: 'Las facturas de este rfc serán aceptadas',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si, aprobar',
                confirmButtonColor: '#2BBA45',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                showCloseButton: true
            }).then(async (result) => {
                if (result.value) {
                    let loader = this.$loading.show()
                    let now = new Date()
                    this.record.history.unshift({
                        date: moment(now.toISOString()).locale('es').format('DD-MMM-YYYY hh:mm A'),
                        type: 'APPROVED',
                        info: 'Empresa aprobada',
                        user: this.$store.getters.user.userName
                    })
                    this.record.statusId = FactoringCompanyStatus.APPROVED
                    await this.saveRecord()
                    try {
                        let request = { company: this.record, action: 'Empresa dragón Aprobada' }
                        UserNotificationService.notifyOnCompanyUpdate(request)
                    } catch (error) {
                        console.error('error on notifyOnCompanyUpdate', error)
                    }
                    // try {
                    //     IOCService.nofifyIOC(this.record)
                    // } catch (error) {
                    //     console.error('error on notify IOC', error)
                    // }
                    loader.hide()
                    this.$swal('Operacion exitosa', 'Se ha aprobado la empresa', 'success')
                }
            })
        },
        disableRecord () {
            if (!Utils.userHasPerm('can_disable_factoring_company')) {
                this.$swal('Error', 'No tienes permiso para realizar la accion', 'error')
                return
            }

            this.$swal({
                title: '¿Deseas deshabilitar la empresa?',
                html: 'Las facturas de este rfc serán rechazadas mientras esté deshabilitada',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si, deshabilitar',
                confirmButtonColor: '#2BBA45',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                showCloseButton: true
            }).then(async (result) => {
                if (result.value) {
                    let loader = this.$loading.show()
                    let now = new Date()
                    this.record.history.unshift({
                        date: moment(now.toISOString()).locale('es').format('DD-MMM-YYYY hh:mm A'),
                        type: 'DISABLED',
                        info: 'Empresa fue deshabilitada para la subida de facturas',
                        user: this.$store.getters.user.userName
                    })
                    this.record.statusId = FactoringCompanyStatus.DISABLED
                    await this.saveRecord()
                    try {
                        let request = { company: this.record, action: 'Empresa dragón Deshabilitada' }
                        UserNotificationService.notifyOnCompanyUpdate(request)
                    } catch (error) {
                        console.error('error on notifyOnCompanyUpdate', error)
                    }
                    // try {
                    //     IOCService.nofifyIOC(this.record)
                    // } catch (error) {
                    //     console.error('error on notify IOC', error)
                    // }
                    loader.hide()
                    this.$swal('Operacion exitosa', 'Se ha deshabilitado la empresa', 'success')
                }
            })
        },
        enableRecord () {
            if (!Utils.userHasPerm('can_enable_factoring_company')) {
                this.$swal('Error', 'No tienes permiso para realizar la accion', 'error')
                return
            }

            if (!this.record.factorajeBlu && !this.record.bluIndex) {
                this.$swal('Error', 'Debes editar y asignar el producto a autorizar', 'error')
                return
            }

            this.$swal({
                title: '¿Deseas rehablitar la empresa?',
                html: 'Las facturas de este rfc serán aceptadas nuevamente',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si, rehabilitar',
                confirmButtonColor: '#2BBA45',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                showCloseButton: true
            }).then(async (result) => {
                if (result.value) {
                    let loader = this.$loading.show()
                    let now = new Date()
                    this.record.history.unshift({
                        date: moment(now.toISOString()).locale('es').format('DD-MMM-YYYY hh:mm A'),
                        type: 'APPROVED',
                        info: 'Empresa deshabilitada fue habilitada nuevamente',
                        user: this.$store.getters.user.userName
                    })
                    this.record.statusId = FactoringCompanyStatus.APPROVED
                    await this.saveRecord()
                    try {
                        let request = { company: this.record, action: 'Empresa dragón rehabilitada (aprobada)' }
                        UserNotificationService.notifyOnCompanyUpdate(request)
                    } catch (error) {
                        console.error('error on notifyOnCompanyUpdate', error)
                    }
                    // try {
                    //     IOCService.nofifyIOC(this.record)
                    // } catch (error) {
                    //     console.error('error on notify IOC', error)
                    // }
                    loader.hide()
                    this.$swal('Operacion exitosa', 'Se ha habilitado nuevamente la empresa', 'success')
                }
            })
        },
        approveRejectedRecord () {
            if (!Utils.userHasPerm('can_approve_rejected_factoring_company')) {
                this.$swal('Error', 'No tienes permiso para realizar la accion', 'error')
                return
            }

            if (!this.record.factorajeBlu && !this.record.bluIndex) {
                this.$swal('Error', 'Debes editar y asignar el producto a autorizar', 'error')
                return
            }

            this.$swal({
                title: '¿Deseas habilitar la empresa?',
                html: 'Las facturas de este rfc serán aceptadas',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: 'Si, aprobar',
                confirmButtonColor: '#2BBA45',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                showCloseButton: true
            }).then(async (result) => {
                if (result.value) {
                    let loader = this.$loading.show()
                    let now = new Date()
                    this.record.history.unshift({
                        date: moment(now.toISOString()).locale('es').format('DD-MMM-YYYY hh:mm A'),
                        type: 'APPROVED',
                        info: 'Empresa rechazada fue habilitada',
                        user: this.$store.getters.user.userName
                    })
                    this.record.statusId = FactoringCompanyStatus.APPROVED
                    await this.saveRecord()
                    try {
                        let request = { company: this.record, action: 'Empresa dragón aprobada' }
                        UserNotificationService.notifyOnCompanyUpdate(request)
                    } catch (error) {
                        console.error('error on notifyOnCompanyUpdate', error)
                    }
                    // try {
                    //     IOCService.nofifyIOC(this.record)
                    // } catch (error) {
                    //     console.error('error on notify IOC', error)
                    // }
                    loader.hide()
                    this.$swal('Operacion exitosa', 'Se ha habilitado nuevamente la empresa', 'success')
                }
            })
        },
        addComment () {
            if (!Utils.userHasPerm('can_add_comment_factoring_company')) {
                this.$swal('Error', 'No tienes permiso para realizar la accion', 'error')
                return
            }
            this.commentsErrors = ''
            let comment = this.newComment.replace(/^\s+|\s+$/g, '')
            if (comment.length === 0) {
                Vue.set(this, 'newComment', '')
                this.commentsErrors = 'Ingresa un comentario válido'
                return
            }

            let now = new Date()
            comment = {
                'comment': this.newComment,
                'added_by': this.$store.getters.user.userName,
                'added_on': moment(now.toISOString()).format('DD-MMM-YYYY hh:mm A')
            }
            this.record.comments.unshift(comment)
            if (this.record.companyId) {
                this.saveRecord()
            }
            Vue.set(this, 'newComment', '')
            Vue.set(this, 'commentsErrors', '')
        },
        reviewInvoices () {
            if (!Utils.userHasPerm('can_authorize_invoices_of_disabled_dragons')) {
                this.$swal('Error', 'No tienes permiso para realizar la accion', 'error')
                return
            }
            this.record.invoicesToReview = this.record.invoices.filter(item => item.status === FactoringDocumentReviewStatus.PENDING)
            this.record.invoicesToReview = this.record.invoicesToReview.map(item => {
                let invoice = Object.assign({}, item)
                invoice.newStatus = item.status
                return invoice
            })
            this.reviewModalKey += 1
            this.showReviewModal = true
        },
        async saveReviews (record) {
            if (record.cancel) {
                Vue.set(this, 'showReviewModal', false)
                return
            }
            let loader = this.$loading.show()
            Vue.set(this, 'showReviewModal', false)
            try {
                if (record.reviewedInvoices.length > 0) {
                    await FactoringDocumentReviewService.update({ invoices: record.reviewedInvoices })
                }
                await this.loadRecord(this.$route.params.id)
                loader.hide()
            } catch (error) {
                console.error(error)
                loader.hide()
                this.$swal('Error', 'Error al guardar reviews', 'error')
            }
        },
        openReviewedInvoices () {
            if (!Utils.userHasPerm('can_authorize_invoices_of_disabled_dragons')) {
                this.$swal('Error', 'No tienes permiso para realizar la accion', 'error')
                return
            }
            this.reviewedInvoicesKey += 1
            this.showHistoryReviewModal = true
        },
        closeReviewesInvoices () {
            this.showHistoryReviewModal = false
        }
    }
}
</script>
<style scoped>
    .header-menu {
        padding-top:5px;
        padding-bottom:5px;
        background-color: #1C1D1D;
        color: white;
        font-weight: bolder;
    }
    .item {
        margin-bottom: 5px;
    }
    .left-spaced {
        margin-left: 20px;
    }
    .accepted {
      color: green;
    }
    .rejected {
      color: red;
    }
</style>
