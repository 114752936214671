import axios from 'axios'

const url = process.env.VUE_APP_BLUENV === 'development' ? '/api' : '/api'
const headers = {
    'Authorization': 'Bearer 603c0d60-603d-11e8-bcc7-c9299595dc11',
    'Content-Type': 'application/json'
}

export default class FactoringDocumentReviewService {
    static async update (invoices) {
        return new Promise(async (resolve, reject) => {
            try {
                let { data } = await axios.put(`${url}/factoringDocumentReview/`, invoices, { headers: headers })
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
}
