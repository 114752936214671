<template>
    <section>
        <div class="ui basic segment">
            <!-- <a :href="baseUrl" class="ui left basic button">
                <i class="reply icon"></i>Regresar
            </a> -->
            <h2 class="ui grey right floated header">
                <i class="building icon"></i>
                Empresas Dragón para Factoraje
            </h2>
        </div>
        <div class="ui form">
            <div class="fields">
                <div class="four wide field">
                    <label>R.F.C. o Nombre de la empresa</label>
                    <input type="text" placeholder="RFC o Nombre de la empresa" v-model="searchRecord.name" @keydown.enter="searchRecords()">
                </div>
                <div class="eight wide field">
                    <label>Estatus</label>
                    <sui-dropdown
                        fluid
                        multiple
                        :options="statusOptions"
                        placeholder="Estatus"
                        selection
                        v-model="statusIds"
                    />
                    <!-- @input="searchRecords()" -->
                </div>
                <div class="four wide field">
                    <br>
                    <button class="ui left floated primary button" @click="searchRecords()"><i class="search icon"></i>Buscar</button>
                    <button class="ui left floated button" @click="restoreRecords()">Limpiar</button>
                </div>
            </div>
        </div>
        <button @click="addRecord()" class="ui right floated green button">Agregar</button><br><br>
        <div class="tableFixHead">
            <sui-table color="black" selectable celled>
              <sui-table-header>
                <sui-table-row>
                  <sui-table-header-cell :width="2">RFC</sui-table-header-cell>
                  <sui-table-header-cell :width="5">Razón social</sui-table-header-cell>
                  <sui-table-header-cell :width="5">Nombre Comercial</sui-table-header-cell>
                  <sui-table-header-cell :width="1">Factoraje Blú</sui-table-header-cell>
                  <sui-table-header-cell :width="1">Blú Index</sui-table-header-cell>
                  <sui-table-header-cell :width="2">Estatus</sui-table-header-cell>
                </sui-table-row>
              </sui-table-header>
              <sui-table-body>
                <sui-table-row v-if="records.length === 0">
                  <sui-table-cell textAlign="center" colspan="6"><strong>No se encontraron registros</strong></sui-table-cell>
                </sui-table-row>
                <sui-table-row v-for="record in records" :key="record.companyId" @click="detailRecord(record.companyId)">
                  <sui-table-cell>{{ record.rfc }}</sui-table-cell>
                  <sui-table-cell>{{ record.razonSocial }}</sui-table-cell>
                  <sui-table-cell>{{ record.nombreComercial }}</sui-table-cell>
                  <sui-table-cell textAlign="center" v-if="record.factorajeBlu"><i class="green check icon"></i></sui-table-cell>
                  <sui-table-cell textAlign="center" v-else><i class="red times icon"></i></sui-table-cell>
                  <sui-table-cell textAlign="center" v-if="record.bluIndex"><i class="ui green check icon"></i></sui-table-cell>
                  <sui-table-cell textAlign="center" v-else><i class="red times icon"></i></sui-table-cell>
                  <sui-table-cell textAlign="center">
                      <h6 class="ui header" :class="Utils.getStatusInfo(record.statusId).color">
                          {{ Utils.getStatusInfo(record.statusId).name }}
                      </h6>
                  </sui-table-cell>
                </sui-table-row>
              </sui-table-body>
              <sui-table-footer>
                <sui-table-row >
                  <sui-table-header-cell colspan="6" text-align="right">
                    <Pagination :total="total" :page-size="searchRecord.pageSize" :callback="loadRecords"/>
                  </sui-table-header-cell>
                </sui-table-row>
              </sui-table-footer>
            </sui-table>
        </div>
    </section>
</template>

<script>
import FactoringCompanyService from '@/services/FactoringCompanyService'
import Pagination from '@/components/Helpers/Pagination.vue'
import Utils from '@/common/Utils.js'
import FactoringCompanyStatus from '@/common/FactoringCompanyStatus.js'

export default {
    components: { Pagination },
    data () {
        return {
            Utils: Utils,
            FactoringCompanyStatus: FactoringCompanyStatus,
            statusOptions: [
                { value: FactoringCompanyStatus.APPROVED, text: Utils.getStatusInfo(FactoringCompanyStatus.APPROVED).name },
                { value: FactoringCompanyStatus.DISABLED, text: Utils.getStatusInfo(FactoringCompanyStatus.DISABLED).name },
                { value: FactoringCompanyStatus.PENDING, text: Utils.getStatusInfo(FactoringCompanyStatus.PENDING).name },
                { value: FactoringCompanyStatus.REJECTED, text: Utils.getStatusInfo(FactoringCompanyStatus.REJECTED).name },
                { value: FactoringCompanyStatus.REJECTED_FOR_PLD, text: Utils.getStatusInfo(FactoringCompanyStatus.REJECTED_FOR_PLD).name },
                { value: FactoringCompanyStatus.REJECTED_FOR_SHELL_COMPANY, text: Utils.getStatusInfo(FactoringCompanyStatus.REJECTED_FOR_SHELL_COMPANY).name }
            ],
            searchRecord: {
                page: 1,
                pageSize: 25,
                order: 'ASC'
            },
            statusIds: [],
            total: 0,
            records: [],
            showCreateModal: false,
            newUser: {}
        }
    },
    beforeMount () {
        if (!this.$store.getters.isLoggedIn) {
            window.location.replace('/login')
        }
        this.loadRecords(1)
    },
    methods: {
        editUser (userId) {
            this.$router.push({ path: `/userDetails/${userId}` })
        },
        searchRecords () {
            this.searchRecord.page = 1
            this.searchRecord.statusId = this.statusIds
            this.loadRecords(1)
        },
        restoreRecords () {
            this.searchRecord = {
                page: 1,
                pageSize: 25,
                order: 'ASC'
            }
            this.loadRecords(1)
        },
        async loadRecords (page) {
            let loader = this.$loading.show()
            this.records = []
            if (page && typeof (page) !== 'object') {
                this.searchRecord.page = page
            }
            try {
                let data = await FactoringCompanyService.getAll(this.searchRecord)
                this.records = data.data
                this.total = data.count
                loader.hide()
            } catch (error) {
                loader.hide()
                console.error(error)
            }
        },
        detailRecord (id) {
            this.$router.push({ path: '/settings/empresa-dragon-detail/' + id })
        },
        addRecord () {
            if (!Utils.userHasPerm('can_add_factoring_company')) {
                this.$swal({
                    title: 'Error',
                    text: 'No tienes permiso para realizar la accion',
                    type: 'error'
                })
                return
            }
            this.$router.push({ path: '/settings/empresa-dragon-detail/' })
        }
    }
}
</script>

<style scoped>
    .ui table { margin-top: 20px; }
/*    .tableFixHead {
        margin-top: 30px;
        overflow-y: auto;
        height: 600px;
    }
    .tableFixHead thead th {
        position: sticky;
        top: 0;
    }*/
</style>
