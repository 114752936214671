<template>
    <section>
        <modal  v-if="showModal" @close="showModal = false"
                :title="'Historial de facturas: ' + company.razonSocial"
                :sizeOfModal="'fullscreen'"
                :allowScrolling="true"
                :showElement="showModal">
            <template v-slot:modalContent>
                <div>
                    <sui-table compact>
                        <sui-table-header>
                          <sui-table-row>
                                <sui-table-header-cell style="width: 25%">Cliente</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtc">Folio</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtc">Fecha de Emisión</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtc">Compromiso de Pago</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtr">Total</sui-table-header-cell>
                                <sui-table-header-cell style="width: 10%" class="txtc">Moneda</sui-table-header-cell>
                                <sui-table-header-cell style="width: 25%" class="txtc">Accion</sui-table-header-cell>
                          </sui-table-row>
                        </sui-table-header>
                        <sui-table-body>
                            <sui-table-row v-for="invoice of reviewedInvoices" :key="invoice.reviewId">
                                <sui-table-cell>{{invoice.rfc}} <br> <strong>{{invoice.entityName | upper }}</strong></sui-table-cell>
                                <sui-table-cell class="txtc">{{invoice.folio}}</sui-table-cell>
                                <sui-table-cell class="txtc">{{invoice.emittedOn | formatDate }}</sui-table-cell>
                                <sui-table-cell class="txtc">{{invoice.dueDate | formatDate }}</sui-table-cell>
                                <sui-table-cell class="txtr">{{invoice.total | currency}}</sui-table-cell>
                                <sui-table-cell class="txtc">{{invoice.currency}}</sui-table-cell>
                                <sui-table-cell class="txtc">
                                    <span class="ui sub header" :class="Utils.getReviewDocumentStatusInfo(invoice.status).color">
                                        {{ Utils.getReviewDocumentStatusInfo(invoice.status).name }}
                                    </span><br>
                                    <b>Fecha: </b>{{invoice.reviewOn | formatDate}}<br>
                                    <b>Usuario: </b>{{invoice.userName}}
                                </sui-table-cell>
                            </sui-table-row>
                        </sui-table-body>
                    </sui-table>
                   <br/>
                </div>
            </template>
            <template v-slot:modalFooter>
                <div class="actions">
                    <sui-button @click="close(true)">Cerrar</sui-button>
                </div>
            </template>
        </modal>
    </section>
</template>

<script>

import Modal from '@/components/Helpers/Modal.vue'
import FactoringDocumentReviewStatus from '@/common/FactoringDocumentReviewStatus.js'
import Utils from '@/common/Utils.js'

export default {
    name: 'ReviewedInvoices',
    components: { Modal },
    props: ['showModal', 'company'],
    data () {
        return {
            Utils: Utils,
            FactoringDocumentReviewStatus: FactoringDocumentReviewStatus,
            record: {
                cancel: false
            }
        }
    },
    computed: {
        reviewedInvoices () {
            return this.company.invoices.filter(item => item.status !== FactoringDocumentReviewStatus.PENDING)
        }
    },
    methods: {
        close () {
            this.$emit('modalResponse')
        }
    }
}
</script>
<style scoped>
    .txtc { text-align: center!important }
    .txtr { text-align: right!important }
</style>
