import axios from 'axios'

const url = process.env.VUE_APP_BLUENV === 'development' ? '/api' : '/api'
const headers = {
    'Authorization': 'Bearer 603c0d60-603d-11e8-bcc7-c9299595dc11',
    'Content-Type': 'application/json'
}

export default class UserService {
    static async getAllUsers () {
        try {
            let { data } = await axios.get(`${url}/getUsers`, { headers })
            if (data.status === 'success') return Promise.resolve(data.data)
            return Promise.resolve([])
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }

    static async createUser (newUser) {
        try {
            let { data } = await axios.post(`${url}/createUser`, newUser, { headers })
            if (data.status === 'success') return Promise.resolve(true)
            else return Promise.resolve(false)
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }

    static async getById (userId) {
        try {
            let { data } = await axios.get(`${url}/getUser/${userId}`, { headers })
            if (data.status === 'success') return Promise.resolve(data.data)
            return Promise.resolve({})
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }

    static async saveUser (userData) {
        try {
            let { data } = await axios.post(`${url}/saveUser`, userData, { headers })
            if (data.status === 'success') return Promise.resolve(true)
            return Promise.resolve(false)
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }
}
