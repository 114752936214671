import axios from 'axios'

const url = process.env.VUE_APP_BLUENV === 'development' ? '/api' : '/api'

export default class NotificationService {
    static async addNew (userId, description, uri) {
        try {
            let { data } = await axios.post(`${url}/addNotification`, {
                userId, description, uri
            })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async getNotifications (userId) {
        try {
            let { data } = await axios.post(`${url}/notifications`, {
                userId
            })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async readAll (userId) {
        try {
            let { data } = await axios.post(`${url}/readAllNotifications`, {
                userId
            })
            return Promise.resolve(data)
        } catch (error) {
            Promise.reject(error)
        }
    }

    static async readOne (userId, notificationId) {
        try {
            let { data } = await axios.post(`${url}/readNotification`, {
                userId, notificationId
            })
            return Promise.resolve(data)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
