import axios from 'axios'

const url = process.env.VUE_APP_BLUENV === 'development' ? '/api' : '/api'
const headers = {
    'Authorization': 'Bearer 603c0d60-603d-11e8-bcc7-c9299595dc11',
    'Content-Type': 'application/json'
}

export default class FactoringCompanyService {
    static async getAll (params) {
        return new Promise(async (resolve, reject) => {
            try {
                let { data } = await axios.get(`${url}/factoringCompanies`, { headers: headers, params: params })
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static async getOne (id, params) {
        return new Promise(async (resolve, reject) => {
            try {
                let { data } = await axios.get(`${url}/factoringCompany/${id}`, { headers: headers, params: params })
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static async getByRFC (params) {
        return new Promise(async (resolve, reject) => {
            try {
                let { data } = await axios.get(`${url}/factoringCompanyByRFC/`, { headers: headers, params: params })
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static async create (companyData) {
        return new Promise(async (resolve, reject) => {
            try {
                let { data } = await axios.post(`${url}/factoringCompany/`, companyData, { headers: headers })
                resolve(data)
            } catch (error) {
                reject(error.response)
            }
        })
    }

    static async update (company) {
        return new Promise(async (resolve, reject) => {
            try {
                let { data } = await axios.put(`${url}/factoringCompany/${company.companyId}`, company, { headers: headers })
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
}
