<template>
  <div class="home"></div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'home',
    components: {},
    data () {
        return {
            lastName: undefined
        }
    },
    beforeMount () {
        if (!this.$store.getters.isLoggedIn) {
            window.location.replace('/login')
        }

        if (this.$store.getters.user.recoverPass) {
            window.location.replace('/changePassword')
        }
    }
}
</script>
