<template>
    <div>
        <div class="loginWrapper">
            <div class="ui raised very padded center aligned text container segment">
                <h1 class="ui header">Inicia sesión</h1>
                <div class="ui form">
                    <div class="field">
                        <input
                            type="text"
                            name="email"
                            placeholder="Nombre de usuario"
                            v-model="username"
                        />
                    </div>
                    <div class="field">
                        <input
                            type="password"
                            name="password"
                            placeholder="Contraseña"
                            v-model="passw"
                            v-on:keyup.enter="loginAction"
                        />
                    </div>
                    <a
                        class="ui blue right floated button"
                        type="submit"
                        @click="loginAction"
                    >Ingresar</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data () {
        return {
            username: undefined,
            passw: undefined
        }
    },
    methods: {
        async loginAction () {
            if (!this.username || !this.passw) {
                this.$swal({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'warning',
                    title: 'Datos incompletos',
                    text: 'Debes llenar todos los campos'
                })
                return
            }

            await this.$store.dispatch('login', {
                username: this.username,
                passw: this.passw
            })

            if (this.$store.getters.isLoggedIn) {
                localStorage.clear()
                if (this.$store.getters.user.roleId === 20) {
                    window.location.href = '/promotoras/solicitud.php'
                } else {
                    window.location.href = '/'
                }
            }
        }
    }
}
</script>

<style scoped>
.loginWrapper {
    height: 100vh;
    align-content: center;
    display: grid;
    background: url(/pattern.png);
}
</style>
