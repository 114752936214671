import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

axios.defaults.withCredentials = true

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoggedIn: null,
        user: {}
    },
    mutations: {
        SET_ISLOGGEDIN: function (state, user) {
            state.isLoggedIn = user
        },
        SET_USER: function (state, user) {
            state.user = user
        }
    },
    actions: {
        setUser ({ commit }, { user, isLoggedIn }) {
            commit('SET_ISLOGGEDIN', isLoggedIn)
            commit('SET_USER', user)
        },
        async login ({ commit }, { username, passw }) {
            try {
                const { data } = await axios.post(`/api/login`, { username, passw })
                if (data.status === 'success') {
                    commit('SET_ISLOGGEDIN', data.data.isLoggedIn)
                    commit('SET_USER', data.data.user)
                } else {
                    Vue.swal({
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'error',
                        title: 'Datos incorrectos',
                        text: data.message
                    })
                }
                return Promise.resolve()
            } catch (error) {
                console.error(error)
            }
        },
        async logout ({ commit }) {
            try {
                const { data } = await axios.post(`/api/logout`)
                if (data.status === 'success') {
                    commit('SET_ISLOGGEDIN', 0)
                    commit('SET_USER', {})
                }
                return Promise.resolve()
            } catch (error) {
                console.error(error)
            }
        }
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        user: state => state.user
    }
})
