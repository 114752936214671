<template>
    <div>
        <div class="ui basic segment" v-show="importData.length === 0">
            <div class="ui warning message">
                <i class="close icon"></i>
                <div class="header">
                    <a
                        target="_blank"
                        href="https://docs.google.com/spreadsheets/d/1yQCRpyHzK2KjR-CmWpoovAW8xfa99MvonGbUoLk2kwY/edit?usp=sharing"
                        >Descarga aquí</a
                    >
                    el formato de ejemplo para importar clientes.
                </div>
                <ul class="list">
                    <li>
                        El archivo debe contener
                        <strong>solamente</strong> los nuevos créditos a
                        importar
                    </li>
                    <li>
                        La primera linea se consideran titulos y se
                        <strong>ignorará</strong> al procesar el archivo
                    </li>
                    <li>
                        Los campos marcados con azul son
                        <strong>obligatorios</strong>. Si falta un campo
                        obligatorio se ignorará la linea.
                    </li>
                    <li>
                        Los campos
                        <strong>Banco</strong>,
                        <strong>Número de cuenta</strong> y
                        <strong>CLABE</strong> son oligatorios si es
                        transferencia.
                    </li>
                </ul>
            </div>

            <div class="ui form">
                <div class="field">
                    <label>Archivo de clientes a importar:</label>
                    <input
                        type="file"
                        name="file"
                        ref="file"
                        @change="handleFileUpload()"
                    />
                </div>
                <div class="ui primary button" @click="submitFile">
                    Importar
                </div>
            </div>
        </div>
        <div class="ui basic segment" v-show="importData.length > 0">
            <h3 is="sui-header" dividing>
                Confirmación de clientes a importar
            </h3>
            <sui-message
                content="Todas las columnas serán procesadas aunque en las siguientes tablas no se muestren."
            />

            <sui-accordion exclusive>
                <sui-accordion-title active>
                    <sui-icon name="dropdown" />Mostrar filas que seran
                    ignoradas por falta de algún dato.
                </sui-accordion-title>
                <sui-accordion-content active>
                    <table class="ui compact small table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Fecha Nacimiento</th>
                                <th>Dirección</th>
                                <th>Ciudad</th>
                                <th>Teléfono</th>
                                <!-- <th>RFC</th>
                                <th>CURP</th>-->
                                <th>Promotor(a)</th>
                                <th>Monto</th>
                                <th>Fecha Inicio</th>
                                <th>Duración</th>
                                <th>Frecuencia</th>
                                <th>Tasa Anual</th>
                                <th>Tasa moratorio</th>
                                <th>Producto</th>
                                <th>Folio</th>
                                <th>Campo Faltante</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="error"
                                v-for="(ignored, index) in dataIgnored"
                                :key="index"
                            >
                                <td>{{ ignored.entityName }}</td>
                                <td>{{ ignored.birthDate }}</td>
                                <td>{{ ignored.address }}</td>
                                <td>{{ ignored.city }}</td>
                                <td>{{ ignored.phone }}</td>
                                <td>{{ ignored.promoter }}</td>
                                <td>{{ ignored.amount | currency }}</td>
                                <td>{{ ignored.startDate }}</td>
                                <td>{{ ignored.duration }}</td>
                                <td>{{ ignored.frecuency }}</td>
                                <td>{{ ignored.interestRate }}%</td>
                                <td>{{ ignored.moratoriumRate }}%</td>
                                <td>{{ ignored.product }}</td>
                                <td>{{ ignored.folioUsed }}</td>
                                <td>{{ ignored.fieldMissing }}</td>
                            </tr>
                        </tbody>
                    </table>
                </sui-accordion-content>
            </sui-accordion>
            <br />
            <div class="ui right floated primary button" @click="confirmImport">
                Confirmar importación
            </div>
            <br />
            <br />
            <table class="ui compact small table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Fecha Nacimiento</th>
                        <th>Dirección</th>
                        <th>Ciudad</th>
                        <th>Teléfono</th>
                        <!-- <th>RFC</th>
                        <th>CURP</th>-->
                        <th>Promotor(a)</th>
                        <th>Monto</th>
                        <th>Fecha Inicio</th>
                        <th>Duración</th>
                        <th>Frecuencia</th>
                        <th>Tasa Anual</th>
                        <th>Tasa moratorio</th>
                        <th>Producto</th>
                        <th>Folio a reservar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(client, index) in dataToImport" :key="index">
                        <td>{{ client.entityName }}</td>
                        <td>{{ client.birthDate }}</td>
                        <td>{{ client.address }}</td>
                        <td>{{ client.city }}</td>
                        <td>{{ client.phone }}</td>
                        <!-- <td>{{ client.rfc }}</td>
                        <td>{{ client.curp }}</td>-->
                        <td>{{ client.promoter }}</td>
                        <td>{{ client.amount | currency }}</td>
                        <td>{{ client.startDate }}</td>
                        <td>{{ client.duration }}</td>
                        <td>{{ client.frecuency }}</td>
                        <td>{{ client.interestRate }}%</td>
                        <td>{{ client.moratoriumRate }}%</td>
                        <td>{{ client.product }}</td>
                        <td>{{ client.folio }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import ApiService from '@/services/ApiService'

export default {
    name: 'MasterImporter',
    data () {
        return {
            baseUrl: process.env.VUE_APP_BASEPATH || '/',
            file: '',
            fileName: '',
            importData: [],
            totals: {}
        }
    },
    methods: {
        handleFileUpload () {
            this.file = this.$refs.file.files[0]
        },
        async submitFile () {
            if (!this.file) {
                this.$swal({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    showCloseButton: true,
                    timer: 10000,
                    icon: 'error',
                    title: 'Ooppss',
                    html: `Selecciona el archivo con la información a importar`
                })
                return
            }

            this.loader = this.$loading.show()

            let response = await ApiService.uploadClientsFile(this.file)

            if (response.status === 'success') {
                this.fileName = response.fileName
                this.getImportData()
            }
        },
        async getImportData () {
            let response = await ApiService.getImportData(this.fileName)

            if (response.status === 'success') {
                this.importData = response.importData
                this.totals = response.totals
            } else if (response.status === 'error') {
                this.$swal.fire({
                    title: 'Error',
                    text: response.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar'
                })
            }

            this.loader.hide()
        },
        confirmImport () {
            this.$swal.fire({
                title: '¿Estas seguro?',
                text: `Se crearán ${this.totals.imported} nuevos clientes con su dispersión y se ignorarán ${this.totals.ignored} prospectos.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then(async result => {
                if (result.value) {
                    this.loader = this.$loading.show()
                    let response = await ApiService.confirmImport(this.fileName)

                    if (response.status === 'success') {
                        this.loader.hide()
                        this.$swal.fire({
                            title: 'Importación en proceso',
                            text: 'Se esta realizando la importación de tus clientes y sus dispersiones, esto puede tardar unos minutos',
                            icon: 'info',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Aceptar'
                        }).then(async result => {
                            if (result.value) {
                                this.$router.push({ path: `${this.baseUrl}` })
                            }
                        })
                    }
                }
            })
        }
    },
    computed: {
        dataIgnored () {
            let ignored = this.importData.filter(_ => _.ignored)
            return ignored
        },
        dataToImport () {
            let toImport = this.importData.filter(_ => !_.ignored)
            return toImport
        }
    }
}
</script>

<style>
</style>
