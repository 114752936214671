<template>
    <div class="ui right floated pagination menu">
        <a class="icon item" v-if="currentPage > 1 && total > pageSize" @click.prevent="showFirst()">
          <i class="left angle double icon"></i>
        </a>
        <a v-if="currentPage > 1 && total > pageSize" @click.prevent="changePage(currentPage - 1)" class="icon item">
          <i class="left angle icon"></i>
        </a>
        <a v-for="num in array" :key="num" :class="{ 'active': num === currentPage }" class="item" @click.prevent="changePage(num)">{{num}}</a>
        <a v-if="currentPage !== lastPage && total > pageSize" @click.prevent="changePage(currentPage + 1)" class="icon item">
          <i class="right angle icon"></i>
        </a>
        <a class="icon item" v-if="currentPage < lastPage && total > pageSize" @click.prevent="showLast()">
          <i class="right angle double icon"></i>
        </a>
    </div>
</template>

<script>

export default {
    props: {
        total: {
            type: Number,
            required: true
        },
        pageSize: {
            type: Number,
            required: true
        },
        callback: {
            type: Function,
            required: true
        },
        options: {
            type: Object
        }
    },
    data () {
        return { currentPage: 1 }
    },
    computed: {
        _total () {
            return this.total
        },
        _pageSize () {
            return this.pageSize
        },
        lastPage () {
            let _total = this._total / this._pageSize
            if (_total < 1) {
                return 1
            }

            if (_total % 1 !== 0) {
                return parseInt(_total + 1)
            }
            return _total
        },
        array () {
            let _from = this.currentPage - 2
            if (_from < 1) {
                _from = 1
            }

            let _to = _from + 4
            if (_to >= this.lastPage) {
                _to = this.lastPage
            }
            let _arr = []
            while (_from <= _to) {
                _arr.push(_from)
                _from++
            }

            return _arr
        },
        config () {
            return Object.assign({
                // offset: 35,
                previousText: '«',
                nextText: '»',
                alwaysShowPrevNext: true
            }, this.options)
        }
    },
    methods: {
        showPrevious () {
            return this.config.alwaysShowPrevNext || this.currentPage > 1
        },
        showNext () {
            return this.config.alwaysShowPrevNext || this.currentPage < this.lastPage
        },
        showFirst () {
            this.currentPage = 1
            this.callback(1)
        },
        showLast () {
            this.currentPage = this.lastPage
            this.callback(this.lastPage)
        },
        changePage (page) {
            if (this.currentPage === page) { return }
            this.currentPage = page
            this.callback(page)
        }
    }
}
</script>
