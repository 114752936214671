<template>
    <a class="item">
        <sui-popup :wide="true" hoverable>
            <a class="ui blue left ribbon label">Notificaciones</a>
            <button
                v-if="unreadNotifications>0"
                class="ui right floated mini basic button"
                @click="markAllAsRead"
            >Marcar todo como leido</button>
            <div class="ui divided items">
                <div
                    class="item"
                    v-for="(notification, index) in latestNotifications"
                    :key="notification.notificationId"
                >
                    <a @click="followLink(index)" class="middle aligned content">
                        <div class="meta">
                            <span class="stay">{{ notification.createdAt }}</span>
                        </div>
                        <div class="description">{{ ellipsis(notification.description) }}</div>
                    </a>
                    <i
                        @click="markAsSeen(index)"
                        class="blue dot circle link icon"
                        v-show="!notification.seen"
                    ></i>
                </div>
            </div>
            <div
                :class="['ui label', {'blue' : unreadNotifications > 0, 'black' : unreadNotifications == 0}]"
                slot="trigger"
            >
                <i class="bell icon"></i>
                <span>{{ unreadNotifications }}</span>
            </div>
            <a
                href="/notifications"
                class="ui mini basic bottom attached button"
                tabindex="0"
            >Ver todas</a>
        </sui-popup>
    </a>
</template>

<script>

export default {
    name: 'Notifications',
    data () {
        return {
            unread: 0,
            notifications: []
        }
    },
    async beforeMount () {
        this.getNotifications()
    },
    methods: {
        ellipsis (notification) {
            if (!notification) return ''
            return notification.length > 85 ? `${notification.substr(0, 85)}...` : notification
        },
        async getNotifications () {
            try {
                let response = await this.$notifications.getNotifications(this.$store.getters.user.userId)
                if (response.status === 'success') this.notifications = response.data.notifications
            } catch (error) {
                console.error(error)
            }
        },
        async followLink (index) {
            try {
                this.markAsSeen(index)

                if (this.notifications[index].uri) window.location = this.notifications[index].uri
            } catch (error) {
                console.error(error)
            }
        },
        async markAsSeen (index) {
            try {
                let response = await this.$notifications.readOne(this.$store.getters.user.userId, this.notifications[index].notificationId)
                if (response.status === 'success') this.notifications[index].seen = true
            } catch (error) {
                console.error(error)
            }
        },
        async markAllAsRead () {
            try {
                let response = await this.$notifications.readAll(this.$store.getters.user.userId)
                if (response.status === 'success') {
                    for (let notification of this.notifications) {
                        notification.seen = true
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
    },
    computed: {
        unreadNotifications () {
            return this.notifications.reduce((accumulator, item) => {
                return accumulator + (item.seen ? 0 : 1)
            }, 0)
        },
        latestNotifications () {
            let latest = this.notifications.slice(0, this.unreadNotifications)

            if (latest.length <= 0 || latest.length <= 9) {
                latest = this.notifications.slice(0, 5)
            } else if (latest.length >= 10) {
                latest = this.notifications.slice(0, 7)
            }

            return latest
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
