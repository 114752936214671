<template>
    <sui-segment basic :class="['', { loading: isLoading }]">
        <span @click="showCreateModal = true" class="ui right floated primary button">Crear usuario</span>
        <h3 is="sui-header" dividing>Usuarios</h3>

        <table class="ui five column selectable table">
            <thead>
                <tr>
                    <th>Usuario</th>
                    <th>Correo</th>
                    <th>Rol</th>
                    <th>Fecha creación</th>
                </tr>
            </thead>
            <tbody>
                <tr @click="editUser(user.userId)" v-for="user in users" :key="user.userId">
                    <td>{{ user.userName }}</td>
                    <td>{{ user.email}}</td>
                    <td>{{ user.roleId}}</td>
                    <td>{{ user.addedAt }}</td>
                </tr>
            </tbody>
        </table>

    <sui-modal size="small" v-model="showCreateModal">
      <sui-modal-header>Crear usuario</sui-modal-header>
      <sui-modal-content>
        <div>
        <sui-form>
            <sui-form-field>
                <label>Nombre completo</label>
                <input placeholder="Ej. Ibrahim Abdul Yahar Badiger" v-model="newUser.fullName"/>
            </sui-form-field>
            <sui-form-field>
                <label>Correo electrónico</label>
                <input placeholder="Ej. correo@ejemplo.com" v-model="newUser.email" />
            </sui-form-field>
        </sui-form>
        </div>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button primary @click="createUser">
          Crear
        </sui-button>
      </sui-modal-actions>
    </sui-modal>

    </sui-segment>
</template>

<script>
import UserService from '@/services/UserService'

export default {
    name: 'Users',
    data () {
        return {
            users: [],
            showCreateModal: false,
            newUser: {}
        }
    },
    beforeMount () {
        if (!this.$store.getters.isLoggedIn) {
            window.location.replace('/login')
        }

        this.fetchUsers()
    },
    methods: {
        editUser (userId) {
            this.$router.push({ path: `/userDetails/${userId}` })
        },
        async fetchUsers () {
            this.isLoading = true
            this.users = await UserService.getAllUsers()
            this.isLoading = false
        },
        async createUser () {
            if (!this.newUser.fullName || !this.newUser.email) {
                this.$swal({
                    icon: 'warning',
                    title: 'Datos incompletos',
                    text: 'Debes llenar todos los datos',
                    toast: true,
                    timer: 4000,
                    position: 'top',
                    showConfirmButton: false
                })
                return
            }
            this.isLoading = true

            let success = await UserService.createUser(this.newUser)

            if (success) {
                this.showCreateModal = false
                this.newUser = {}
                this.fetchUsers()
            }
            this.isLoading = false
        }
    }
}
</script>

<style scoped>

</style>
